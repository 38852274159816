
import '@/assets/HelveticaNeueCyr/stylesheet.css'

import VueCookies from 'vue-cookies'

import CHeader from '@/components/header'
import CContacts from '@/components/contacts'
import CMenu from '@/components/menu'
import Modal from '@/components/modal'
import CIcon from '@/components/icon'

export default
  name: 'app'
  components:
    'c-header': CHeader
    'c-contacts': CContacts
    'c-menu': CMenu
    'c-modal': Modal
    'c-icon': CIcon


  mounted: ->
    if not VueCookies.get 'token'
      window.location.href = 'https://aostng.ru/login/?backurl=/newlk/'
    else
      @$store.dispatch 'get_user'

  computed:
    mobile_menu: ->
      @$store.getters.mobile_menu

    is_modal: ->
      @$store.getters.modal.type

import Vue from 'vue'
import app from './app'
import router from './router'
import store from './store/index'

import VueCookies from 'vue-cookies'

Vue.use(VueCookies)

new Vue({
    router: router,
    store: store,
    render: h => h(app)
}).$mount('#app')


import { mask } from 'vue-the-mask'

export default
  name: 'c-modal'

  directives:
    mask: mask

  data: ->
    pay:
      input: ''
    is_email: ''
    contact_type: 'email'
    printLink: ''

  computed:
    modal: -> @$store.getters.modal

  methods:
    send_pay: ->
      if @pay.input
        email_re = /^[\w-\.]+@[\w-]+\.[a-z]{2,4}$/i

        is_email = email_re.test @pay.input

        @$store.commit 'set_modal_pay_contacts',
          input: @pay.input
          contact_type: @contact_type
        
        @$store.commit 'set_modal_type', null

        @$store.dispatch 'send_pay'
    
    close_modal: ->
      @$store.commit 'set_modal_type', null

#    get_kvitok_sber: ->


  mounted: ->

    if @$store.getters.user
      @pay.input = @$store.getters.user.email
    if @modal.type == 'print'
      that = @
      @$store.dispatch('get_kvitok_sber', @$route.params.lc).then (res) ->
        console.log res
        that.printLink = res
  watch:
    'pay.input': (value) ->

      if +value
        @is_email = false
      else
        @is_email = true

    contact_type: (value) ->
      if value is 'email'
        @pay.input = @$store.getters.user.email
      else
        @pay.input = '+7'

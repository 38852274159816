
import Tabs from '@/components/tabs'
import Button from '@/components/button'
import Icon from '@/components/icon'
import SupportList from '@/components/support-list'

export default
  name: 'support'
  components:
    'c-tabs': Tabs
    'c-button': Button
    'c-icon': Icon
    'c-support-list': SupportList
  
  computed:
    tickets: -> @$store.getters['support/tickets']

  data: ->
    tabs: [
      label: 'Обращения'
      path: '/support'
    ]

  watch:
    tickets: (value) ->
      if value.length > 0 and @$route.name is 'support'
        @$router.push(
          name: 'support-ticket'
          params:
            id: value[0].id
        )

    '$route.name': (value) ->
      if value is 'support'
        await @$store.dispatch 'support/init'

  mounted: ->
    await @$store.dispatch 'support/init'


import SupportList from '@/components/support-list'
import LcItem from '@/components/lc-item'
import AddButton from '@/components/add-button'
import Icon from '@/components/icon'
import Tabs from '@/components/tabs'
import Loading from '@/components/loading'

import DatePicker from 'vue2-datepicker'
import moment from 'moment'

export default
  name: 'Lc'
  components:
    'c-support-list': SupportList
    'c-lc-item': LcItem
    'c-add-button': AddButton
    'c-icon': Icon
    'c-tabs': Tabs
    'c-loading': Loading
    'date-picker': DatePicker
  computed:
    support_tickets: -> @$store.getters['support/tickets']

    lc_list: ->
      @$store.getters.lc_list

    selected_lc_number: ->
      @$store.getters.selected_lc_number

    is_mobile: ->
      screen.width < 760

    is_loading: ->
      @$store.getters.is_loading

    tabs: ->
      toDay = (new Date).getDate()
      startDate = 20
      endDate = 25

      result = [
        label: 'Оплата'
        path: "/lc/#{@selected_lc_number}"
      ,
        label: 'История платежей'
        path: "/lc/#{@selected_lc_number}/history"
      ]
      if toDay >= startDate and toDay <= endDate
        result = [
          label: 'Оплата'
          path: "/lc/#{@selected_lc_number}"
        ,
          label: 'Показания'
          path: "/lc/#{@selected_lc_number}/counters"
        ,
          label: 'История платежей'
          path: "/lc/#{@selected_lc_number}/history"
        ]
      result

  mounted: ->
    if not @lc_list
      @$store.dispatch 'get_lcs'
    # if @$store.getters.user
    #   @$store.dispatch 'get_lc',
    #     lc: @$store.getters.user.lc.number
    #     fio: @$store.getters.user.lc.last_name

    await @$store.dispatch 'support/init'

  watch:
    'date.start': (value) ->
      date_start = moment(value).format 'YYYYMMDDHHmmss'
      date_end = moment(@date.end).format 'YYYYMMDDHHmmss'

      if not value
        date_start = '20151231000000'

      if not @date.end
        date_end = '20221231000000'

      @$store.dispatch 'get_lc_history',
        number: @selected_lc_number
        start: date_start
        end: date_end

    'date.end': (value) ->
      date_start = moment(@date.start).format 'YYYYMMDDHHmmss'
      date_end = moment(value).format 'YYYYMMDDHHmmss'

      if not @date.start
        date_start = '20151231000000'

      if not value
        date_end = '20221231000000'

      @$store.dispatch 'get_lc_history',
        number: @selected_lc_number
        start: date_start
        end: date_end
        is_pay: '1'

  data: ->
    date:
      start: ''
      end: ''


import Tabs from '@/components/tabs'

export default
  name: 'services'
  components:
    'c-tabs': Tabs

  data: ->
    tabs: [
      label: 'Мои заявки',
      path: '/services'
    ]


import { mask } from 'vue-the-mask'
import Multiselect from 'vue-multiselect'
import DatePicker from 'vue2-datepicker'

import 'vue-multiselect/dist/vue-multiselect.min.css'
import 'vue2-datepicker/index.css'

import 'vue2-datepicker/locale/ru'

export default
  name: 'Input'
  props: ['label', 'type', 'value', 'options', 'optionLabel', 'customLabel', 'format', 'required']
  data: ->
    localValue: @value

    is_empty: false
  
  directives:
    mask: mask
  
  components:
    'multiselect': Multiselect
    'date-picker': DatePicker

  computed:
    is_mobile: ->
      screen.width < 760

  watch:
    localValue: (value) ->
      if @type is 'select'
        @$emit 'onselect', value

    value: (value) ->
      @localValue = value

  methods:
    check_empty: ->
      @is_empty = false

      if @required isnt undefined and @localValue is ''
        @is_empty = true



export default

  name: 'contacts'

  computed:
    modals: ->
      @$store.getters.modal

  methods:
    closeOverlay: ->
      @$store.commit 'set_modal_contacts', false


import Vue from 'vue'
import VueRouter from 'vue-router'

import Home from '@/routes/home'

import Lc from '@/routes/lc'
import LcPay from '@/routes/lc/pay'
import LcCounters from '@/routes/lc/counters'
import LcCounter from '@/routes/lc/counter'
import LcHistory from '@/routes/lc/history'
import LcConnect from '@/routes/lc/connect'
import LcSearch from '@/routes/lc/search'

import Services from '@/routes/services'
import ServicesMy from '@/routes/services/my'

import Support from '@/routes/support'
import SupportTicket from '@/routes/support/ticket'
import SupportCreate from '@/routes/support/create'

import Auth from '@/routes/auth'

import Profile from '@/routes/profile'

import ThankYou from '@/routes/thank-you'
import PayFailed from '@/routes/pay-failed'

Vue.use VueRouter

export default new VueRouter
  base: process.env.BASE_URL + '/lk'
  routes: [
    name: 'home'
    path: '/'
    component: Home
    redirect: '/lc'
  ,
    name: 'auth'
    path: '/auth/:token'
    component: Auth
  ,
    name: 'lc'
    path: '/lc'
    component: Lc
    children: [
      name: 'lc-connect'
      path: 'connect'
      component: LcConnect
    ,
      name: 'lc-search'
      path: 'search'
      component: LcSearch
    ,
      name: 'lc-pay'
      path: ':lc'
      component: LcPay
    ,
      name: 'lc-counters'
      path: ':lc/counters'
      component: LcCounters
      children: [
        name: 'lc-counter'
        path: ':id'
        component: LcCounter
      ]
    ,
      name: 'lc-history'
      path: ':lc/history'
      component: LcHistory
    ]
  ,
    name: 'services'
    path: '/services'
    component: Services
    children: [
      name: 'services-my'
      path: '/'
      component: ServicesMy
    ]
  ,
    name: 'support'
    path: '/support'
    component: Support
    children: [
      name: 'support-create'
      path: 'create'
      component: SupportCreate
    ,
      name: 'support-ticket'
      path: ':id'
      component: SupportTicket
    ]
  ,
    name: 'profile'
    path: '/profile'
    component: Profile
  ,
    name: 'thank-you'
    path: '/thank-you'
    component: ThankYou
  ,
    name: 'pay-failed'
    path: '/pay-failed'
    component: PayFailed
  ]


import Button from '@/components/button'
import Loading from '@/components/loading'

export default
  name: 'lc-pay'

  components:
    'c-button': Button
    'c-loading': Loading

  data: ->
    accrued: ''
    calculated: ''
    maintenance: ''
    peni: ''
    others: []
    noSumm: false

  watch:
    peni: (value) ->
      if value < 0
        @peni = value * -1
    
    calculated: (value) ->
      if value < 0
        @calculated = value * -1
    
    accrued: (value) ->
      if value < 0
        @accrued = value * -1
    
    maintenance: (value) ->
      if value < 0
        @maintenance = value * -1

  computed:
    lc_item: ->
      that = @

      if @$store.getters.lc_list
        result = @$store.getters.lc_list.findIndex (item) ->
          item.number is that.$route.params.lc

        if result isnt -1
          @$store.getters.lc_list[result]

    total: -> +@accrued + +@calculated + +@maintenance + +@peni + @others_total

    is_mobile: -> screen.width < 760
    
    lc_others: ->
      others = []

      if @lc_item.debt.others != 0
        if @$store.getters.lc_others
          others = @$store.getters.lc_others

          for others_item, others_item_index in others
            @others.push(
              id: others_item.id
              name: others_item.name
              sum: others_item.sum
              new_value: ''
            )

      others

    others_total: ->
      total = 0

      if @others.length > 0
        for others_item in @others
          total += +others_item.new_value
      
      total

    is_loading: -> @$store.getters.is_loading

  mounted: ->
    @$store.commit 'select_lc', @$route.params.lc
    @$store.dispatch 'get_other_debt', @$route.params.lc

  methods:
    format_price: (value) -> "#{value.toLocaleString('ru-RU')} руб."

    send_pay: ->
      if @total isnt 0
        @noSumm = false
        @$store.commit 'set_modal_pay_data',
          accrued: @accrued
          calculated: @calculated
          maintenance: @maintenance
          others: @others
          peni: @peni
          lc_number: @$route.params.lc

        @$store.commit 'set_modal_type', 'pay'

      else
        @noSumm = true

    get_kvitok_sber: ->
#      @$store.dispatch 'get_kvitok_sber', @$route.params.lc
      @$store.commit 'set_modal_type', 'print'

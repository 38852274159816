
export default
  name: 'CButton'
  props: ['color', 'bg', 'border', 'square', 'size', 'to', 'lc', 'full', 'handle', 'data', 'droplc']
  computed:
    object_class: () ->
      button_color_primary: @color is 'primary'
      button_color_primary_light: @color is 'primary-light'
      button_color_white: @color is 'white'
      button_color_bg: @color is 'bg'
      button_color_mute: @color is 'mute'

      button_bg_primary: @bg is 'primary'
      button_bg_primary_light: @bg is 'primary-light'
      button_bg_white: @bg is 'white'
      button_bg_bg: @bg is 'bg'
      button_bg_mute: @bg is 'mute'
      button_bg_secondary: @bg is 'secondary'

      button_border_primary: @border is 'primary'
      button_border_primary_light: @border is 'primary-light'
      button_border_white: @border is 'white'
      button_border_bg: @border is 'bg'
      button_border_mute: @border is 'mute'

      button_size_big: @size is 'big'

      button_width_full: @full

      button_square: @square

  methods:
    on_click: ->
      if @to
        @$router.push({ path: @to })

      if @lc
        @$store.commit('select_lc', @lc)
      
      if @handle is 'connect'
        @$store.dispatch 'connect_lc', @data

      if @droplc
        @$store.dispatch 'drop_lc',
          lc: @droplc.lc
          last_name: @droplc.last_name

      @$emit 'click'

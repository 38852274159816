
import SupportList from '@/components/support-list'
import row from '@/components/row'
import col from '@/components/col'
import input from '@/components/input'
import button from '@/components/button'
import Loading from '@/components/loading'

export default
  name: 'profile'

  components:
    'c-support-list': SupportList
    'c-row': row
    'c-col': col
    'c-input': input
    'c-button': button
    'c-loading': Loading

  data: ->
    last_name: ''
    first_name: ''
    middle_name: ''
    phone: ''
    email: ''

  computed:
    support_tickets: -> @$store.getters['support/tickets']

    is_loading_profile: -> @$store.getters.is_loading_profile

    user: -> @$store.getters.user

    user_save: -> @$store.getters.user_save

  methods:
    submit: ->
      @$store.commit 'set_user_save', false

      if @last_name isnt '' and @first_name isnt '' and @email isnt ''
        @$store.dispatch 'save_profile',
          last_name: @last_name
          first_name: @first_name
          middle_name: @middle_name
          email: @email

    changePassword: ->
      window.location.href = 'https://aostng.ru/changepassword.php'
  mounted: ->
    @$store.dispatch('get_user')
  
  watch:
    user: (value) ->
      @last_name = value.last_name
      @first_name = value.first_name
      @middle_name = value.middle_name
      @email = value.email
      @phone = value.login

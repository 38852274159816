
import Button from '@/components/button'

export default
  name: 'lc-item'
  props: ['item']

  data: ->
    menu: false

  components:
    'c-button': Button

  # mounted: ->
  #   console.log @item

  computed:
    user_del: ->
      @$store.getters.user_del
    is_can_transfer: ->
      toDay = (new Date).getDate()
      startDate = 20
      endDate = 25
      toDay >= startDate and toDay <= endDate

  methods:
    get_debt: (item) ->
      accrued = if item.debt.accrued > 0 then item.debt.accrued else 0
      calculated = if item.debt.calculated > 0 then item.debt.calculated else 0
      maintenance = if item.debt.maintenance > 0 then item.debt.maintenance else 0
      others = if item.debt.others > 0 then item.debt.others else 0

      accrued + calculated + maintenance + others

    format_price: (value) ->
      "#{value.toLocaleString('ru-RU')} руб."

    droplc: (e, lc) ->
      e.preventDefault()

      @$store.dispatch 'drop_lc',
        lc: lc.lc
        last_name: lc.last_name

    menu_toggle: ->
      @menu = not @menu

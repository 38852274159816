
import Button from '@/components/button'
import Loading from '@/components/loading'
import moment from 'moment'

export default
  name: 'counters'

  components:
    'c-button': Button
    'c-loading': Loading

  data: ->
    records: []
    indication: ''
    selected_counter: null
    notice: ''

  computed:
    lc_item: ->
      that = @

      if @$store.getters.lc_list
        result = @$store.getters.lc_list.findIndex (item) ->
          item.number is that.$route.params.lc

        if result isnt -1
          counters = @$store.getters.lc_list[result].counters

          for counter_item in counters
            @records.push(
              id: counter_item.id
              indication: ''
              old_indication: counter_item.value
              name: counter_item.name
            )

          @$store.getters.lc_list[result]

    is_mobile: -> screen.width < 760

    is_loading: -> @$store.getters.is_loading

  mounted: ->
    @$store.commit 'select_lc', @$route.params.lc

  methods:
    send_indication: ->
      is_can = false
      that = @
      for record in @records
        old_indication_str = record.old_indication + ''

        if record.indication isnt ''
          if +record.indication < record.old_indication
            @notice = "Показания по счетчику #{record.name} меньше предыдущих показаний"
          else if (record.indication.length - old_indication_str.length) > 2
            @notice = "Показания по счетчику #{record.name} слишком большая разница между текущими и предыдущими показаниями"
          else
            @notice = ''
            is_can = true

      if is_can
        @$store.dispatch 'send_indication',
          lc: @lc_item.number
          counters: @records
        .then (res) ->
          if res.response.msg.error
            that.notice = res.response.msg.message

    is_sended: (date) ->
      date is moment().format('DD.MM.YYYY')

  watch:
    indication: (value) ->
      console.log 'value: ', value


import CIcon from '@/components/icon'
export default
  name: 'CMenu'
  components:
    'c-icon': CIcon

  mounted: ->
    # console.log 'route', @$route

  methods:
    on_click: (e) ->
      @$store.commit 'set_mobile_menu', false

import Vue from 'vue'
import Vuex from 'vuex'
import router from '@/router'
import VueCookies from 'vue-cookies'

import support from './modules/support'

Vue.use Vuex

api_url = 'https://api.aostng.ru/api/lk/'

export default new Vuex.Store
  modules:
    support: support

  state:
    lc:
      list: null
      history: null
      others: null

    selected_lc_number: null
    
    is_loading: true

    is_loading_profile: false

    mobile_menu: false

    user: null

    services:
      my: []

    lcs: []

    modal:
      type: null
      pay:
        input: null
        is_email: null
        data: null
      contacts: false
      overlay: false

    user_save: false

    user_del: null
  
  getters:
    is_loading: (state) ->
      state.is_loading

    is_loading_profile: (state) -> state.is_loading_profile

    lc_list: (state) ->
      state.lc.list

    lc_history: (state) ->
      state.lc.history

    lc_others: (state) ->
      state.lc.others

    selected_lc_number: (state) ->
      state.selected_lc_number

    user: (state) ->
      state.user

    mobile_menu: (state) ->
      state.mobile_menu

    services_my: (state) ->
      state.services.my

    modal: (state) ->
      state.modal

    user_save: (state) -> state.user_save

    user_del: (state) -> state.user_del

  mutations:
    set_user_save: (state, value) -> state.user_save = value

    set_loading: (state, value) ->
      state.is_loading = value

    set_loading_profile: (state, value) -> state.is_loading_profile = value

    set_lcs: (state, data) ->
      state.lc.list = data

    select_lc: (state, lc_number) ->
      state.selected_lc_number = lc_number
    
    set_user: (state, user) ->
      state.user = user

    set_lc_history: (state, value) ->
      state.lc.history = value

    set_lc_others: (state, value) ->
      state.lc.others = value

    set_mobile_menu: (state, value) ->
      state.mobile_menu = value

    set_services_my: (state, value) ->
      state.services.my = value

    set_modal_type: (state, value) ->
      state.modal.type = value

    set_modal_pay_data: (state, data) ->
      state.modal.pay.data = data

    set_modal_pay_contacts: (state, data) ->
      state.modal.pay.input = data.input

      if data.contact_type is 'email'
        state.modal.pay.is_email = true
      else
        state.modal.pay.is_email = false

    set_modal_contacts: (state, value) ->
      state.modal.contacts = value
      state.modal.overlay = value

    set_user_del: (state, value) ->
      state.user_del = value

  actions:
    set_token: (ctx, token) ->
      if token
        VueCookies.set 'token', token

        router.push({ path: '/' })

    get_user: (ctx) ->
      ctx.commit 'set_loading_profile', true

      form_data = new FormData
      form_data.append 'method', 'user.check'
      form_data.append 'token', VueCookies.get 'token'

      res = await fetch api_url,
        mode: 'cors'
        method: 'post'
        body: form_data

      json = await res.json()

      ctx.commit 'set_loading_profile', false

      if json.response
        ctx.commit 'set_user',
          id: json.response.userid
          login: json.response.user_array.login
          email: json.response.user_array.email
          first_name: json.response.user_array.name
          last_name: json.response.user_array.last_name
          middle_name: json.response.user_array.second_name

    get_lcs: (ctx) ->
      ctx.commit 'set_loading', true

      form_data = new FormData
      form_data.append 'method', 'user.getuserlk'
      form_data.append 'token', VueCookies.get 'token'

      res = await fetch api_url,
        mode: 'cors'
        method: 'post'
        body: form_data

      json = await res.json()

      ctx.commit 'set_loading', false

      if json.response and json.response.msg
        lc_list = null
        lc_string = ""

        lc_list = json.response.msg.map (lc_item) ->
          { number: lc_item[0], last_name: lc_item[1] }

        console.log 'LC List:', lc_list

        ctx.commit 'set_user_del', json.response.msg[0][1]

        for lc_item, i in lc_list
          if lc_string is ""
            lc_string += lc_item.number
          else
            lc_string += ",#{lc_item.number}"

        ctx.dispatch 'get_lc', lc_string

    get_lc: (ctx, lc_string) ->
      ctx.commit 'set_loading', true

      form_data = new FormData
      form_data.append 'method', 'connect.get'
      form_data.append 'method_connect', 'GetLC'
      form_data.append 'get', "LC=#{lc_string}&FIO=s"

      res = await fetch api_url,
        mode: 'cors'
        method: 'post'
        body: form_data

      json = await res.json()

      ctx.commit 'set_loading', false

      if json.response.msg isnt null and json.response.msg.error isnt null
        lcs = json.response.msg

        for lc_item, i in lcs
          ctx.commit 'set_loading', true

          form_data = new FormData
          form_data.append 'method', 'connect.get'
          form_data.append 'method_connect', 'debtinfo'
          form_data.append 'get', "LC=#{lc_item.lc}"

          res = await fetch api_url,
            mode: 'cors'
            method: 'post'
            body: form_data

          json = await res.json()

          ctx.commit 'set_loading', false

          if not json.response.msg.error
            accrued = 0
            calculated = 0
            maintenance = 0
            peni = 0
            others = 0
            avans = 0

            for debt_item, debt_i in json.response.msg.data
              accrued += +debt_item.sumnachindication
              calculated += +debt_item.sumnachrasch
              maintenance += +debt_item.sumnachto
              peni += +debt_item.sumpeni
              others += +debt_item.sumother
              avans += +debt_item.sumavans

            lcs[i]['debt'] =
              accrued: accrued
              calculated: calculated
              maintenance: maintenance
              peni: peni
              others: others
              avans: avans

        ctx.commit 'set_lcs', lcs.map (item) ->
          number: item.lc
          client: item.fio
          address: item.adress
          last_name: item.fio.split(' ')[0]
          debt: item.debt
          counters: item.counters.map (counter) ->
            verification: counter.datenextverification
            date: counter.dateind
            id: counter.id
            value: counter.indication
            name: counter.name

    get_lc_history: (ctx, data) ->
      ctx.commit 'set_loading', true

      form_data = new FormData
      form_data.append 'method', 'connect.get'
      form_data.append 'method_connect', 'GetReport'
      form_data.append 'get', "LC=#{data.number}&StartDate=#{data.start}&EndDate=#{data.end}&isPay=#{data.is_pay}"

      res = await fetch api_url,
        mode: 'cors'
        method: 'post'
        body: form_data

      json = await res.json()

      ctx.commit 'set_loading', false

      if json.response.msg
        ctx.commit 'set_lc_history', json.response.msg.map (item, index) ->
          date: if item.daypay isnt "" then item.daypay else null
          value: if item.summ isnt "0" then item.summ else null
          counters: if item.counters.length > 0 then item.counters else null

    auth: (ctx, token) ->
      ctx.commit 'set_loading', true

      VueCookies.set 'token', token

      form_data = new FormData
      form_data.append 'method', 'user.check'
      form_data.append 'token', token

      res = await fetch api_url,
        mode: 'cors'
        method: 'post'
        body: form_data

      json = await res.json()

      if json.error
        console.error json.error.msg
      else
        lc_list = null

        lc_string = ""

        if json.response.user_array.lc and json.response.user_array.lc.length > 0
          lc_list = json.response.user_array.lc.map (lc_item) ->
            { number: lc_item[0], last_name: lc_item[1] }

          for lc_item, i in lc_list
            if lc_string is ""
              lc_string += lc_item.number
            else
              lc_string += ",#{lc_item.number}"

        ctx.commit 'set_user',
          id: json.response.userid
          token: json.response.token
          email: json.response.user_array.email
          login: json.response.user_array.login
          first_name: json.response.user_array.name
          last_name: json.response.user_array.last_name
          lc: lc_list

        ctx.dispatch 'get_lc',
          lc: lc_string
        
        ctx.commit 'set_loading', false

        if window.location.hash isnt "#/lc"
          router.push({ name: 'lc' })

    drop_lc: (ctx, data) ->
      ctx.commit 'set_loading', true

      form_data = new FormData
      form_data.append 'method', 'user.deluserlk'
      form_data.append 'token', VueCookies.get 'token'
      form_data.append 'lc', data.lc
      form_data.append 'lastname', data.last_name

      res = await fetch api_url,
        mode: 'cors'
        method: 'post'
        body: form_data

      json = await res.json()

      ctx.commit 'set_loading', false

      if not json.error
        window.location.reload()

    save_profile: (ctx, data) ->
      ctx.commit 'set_loading_profile', true

      form_data = new FormData
      form_data.append 'method', 'user.setuserinfo'
      form_data.append 'name', data.first_name
      form_data.append 'last_name', data.last_name
      form_data.append 'second_name', data.middle_name
      form_data.append 'email', data.email
      form_data.append 'token', VueCookies.get 'token'

      res = await fetch api_url,
        mode: 'cors'
        method: 'post'
        body: form_data

      json = await res.json()

      ctx.commit 'set_loading_profile', false

      if json.response.msg.email
        ctx.commit 'set_user_save', true

    connect_lc: (ctx, data) ->
      ctx.commit 'set_loading', true

      # console.log 'data: ', data

      # form_data = new FormData
      # form_data.append 'method', 'connect.get'
      # form_data.append 'method_connect', 'PutLC'
      # form_data.append 'get', "LC=#{data.lc_number}"
      # form_data.append 'post[firstName]', data.first_name
      # form_data.append 'post[lastName]', data.last_name
      # form_data.append 'post[middleName]', data.middle_name
      # form_data.append 'token', VueCookies.get 'token'

      # res = await fetch api_url,
      #   mode: 'cors'
      #   method: 'post'
      #   body: form_data

      # json = await res.json()

      # console.log 'user: ', json

      # ctx.commit 'set_loading', false

      # if json.response.msg is null
      ctx.commit 'set_loading', true

      form_data2 = new FormData
      form_data2.append 'method', 'user.adduserlk'
      form_data2.append 'lc', data.lc_number
      form_data2.append 'firstname', data.first_name
      form_data2.append 'lastname', data.last_name
      # form_data2.append 'token', VueCookies.get 'token'

      if VueCookies.get 'token'
        form_data2.append 'token', VueCookies.get 'token'

      res2 = await fetch api_url,
        mode: 'cors'
        method: 'post'
        body: form_data2

      json2 = await res2.json()

      console.log 'json2: ', json2

      ctx.commit 'set_loading', false

      if not json2.error
        if json2.response.msg.length > 0
          lcs = json2.response.msg.map (item) ->
            item[0]
          
          lcs = lcs.join ','
        
          ctx.dispatch 'get_lc', lcs

          router.push({ name: 'lc' })

    get_services: (ctx) ->
      ctx.commit 'set_loading', true
      form_data = new FormData
      form_data.append 'method', 'user.getuserservice'
      form_data.append 'token', VueCookies.get 'token'

      res = await fetch api_url,
        mode: 'cors'
        method: 'post'
        body: form_data

      json = await res.json()

      ctx.commit 'set_loading', false

      if json.response.msg isnt null
        ctx.commit 'set_services_my', json.response.msg

    send_indication: (ctx, data) ->
      ctx.commit 'set_loading', true

      obj =
        counters: []

      for counter, counter_index in data.counters
        if counter.indication
          obj.counters.push(
            id: counter.id
            indication: +counter.indication
          )

      json_string = JSON.stringify obj
      
      form_data = new FormData
      form_data.append 'method', 'connect.get'
      form_data.append 'method_connect', 'indication'
      form_data.append 'get', "LC=#{data.lc}"
      form_data.append 'json', json_string

      res = await fetch api_url,
        mode: 'cors'
        method: 'post'
        body: form_data

      json = await res.json()

      if not json.response.msg.error
        router.go()
      else
        ctx.commit 'set_loading', false
        json
      
    get_other_debt: (ctx, lc_number) ->
      ctx.commit 'set_loading', true

      form_data = new FormData
      form_data.append 'method', 'connect.get'
      form_data.append 'method_connect', 'otherdebtinfo'
      form_data.append 'get', "LC=#{lc_number}"

      res = await fetch api_url,
        mode: 'cors'
        method: 'post'
        body: form_data

      json = await res.json()

      ctx.commit 'set_loading', false

      if not json.response.msg.error
        ctx.commit 'set_lc_others', json.response.msg.data

    get_kvitok_sber: (ctx, lc_number) ->
      form_data = new FormData
      form_data.append 'method', 'connect.getfile'
      form_data.append 'method_connect', 'KvitokSber'
      form_data.append 'get', "LC=#{lc_number}"

      res = await fetch api_url,
        mode: 'cors'
        method: 'post'
        body: form_data
        
      json = await res.json()

      if json.response.msg
#        window.open json.response.msg, '_blank'
        return json.response.msg

    send_pay: (ctx) ->
      pay_data = ctx.state.modal.pay.data

      obj =
        other: []

      if ctx.state.modal.pay.is_email
        obj['email'] = ctx.state.modal.pay.input
      else
        obj['phone'] = ctx.state.modal.pay.input

      if pay_data.accrued
        obj['sumNachIndication'] = +pay_data.accrued

      if pay_data.calculated
        obj['sumNachRasch'] = +pay_data.calculated

      if pay_data.maintenance
        obj['sumNachTO'] = +pay_data.maintenance

      if pay_data.peni
        obj['sumPeni'] = +pay_data.peni

      if pay_data.others.length > 0
        for item in pay_data.others
          obj['other'].push(
            id: item.id
            summ: +item.new_value
          )

      json_string = JSON.stringify obj
      
      ctx.commit 'set_loading', true

      form_data = new FormData
      form_data.append 'method', 'connect.get'
      form_data.append 'method_connect', 'createpay'
      form_data.append 'get', "LC=#{pay_data.lc_number}"
      form_data.append 'json', json_string

      res = await fetch api_url,
        mode: 'cors'
        method: 'post'
        body: form_data

      json = await res.json()

      ctx.commit 'set_loading', false

      if not json.response.msg.error
        window.location.href = json.response.msg.link

api_url = 'https://api.aostng.ru/api/lk/'

import VueCookies from 'vue-cookies'
import router from '@/router'

export default
  namespaced: true

  state:
    tickets: []
    categories: []
    archive: null
    messages: []
    ticket: null

    is_loading: false

  getters:
    is_loading: (state) -> state.is_loading

    categories: (state) -> state.categories

    tickets: (state) -> state.tickets

    ticket: (state) -> state.ticket

    messages: (state) -> state.messages

  mutations:
    set_loading: (state, value) -> state.is_loading = value

    set_tickets: (state, value) -> state.tickets = value

    set_categories: (state, value) -> state.categories = value

    set_messages: (state, value) -> state.messages = value

    set_ticket: (state, value) -> state.ticket = value

  actions:
    init: (ctx) ->
      ctx.commit 'set_loading', true

      form_data = new FormData
      form_data.append 'method', 'services.getticketlist'
      form_data.append 'token', VueCookies.get 'token'

      res = await fetch api_url,
        mode: 'cors'
        method: 'post'
        body: form_data

      json = await res.json()

      ctx.commit 'set_loading', false

      if json.response and json.response.msg
        categories = json.response.category_array
        tickets = []

        for ticket, ticket_i in json.response.msg
          category_index = categories.findIndex (item) ->
            item.id is ticket.category_id

          if category_index isnt -1
            tickets.push(
              id: ticket.id
              date: ticket.date_create
              color: ticket.lamp
              category_name: categories[category_index].name
              category_id: ticket.category_id
              name: ticket.title
              messages_count: ticket.messages
            )

        ctx.commit 'set_tickets', tickets

      if json.response.category_array
        categories = []

        for item in json.response.category_array
          categories.push(
            label: item.name
            value: item.id
          )

        ctx.commit 'set_categories', categories

    send_message: (ctx, data) ->
      ctx.commit 'set_loading', true

      form_data = new FormData
      form_data.append 'method', 'services.sendmessage'
      form_data.append 'message', data.message
      form_data.append 'token', VueCookies.get 'token'

      for file in data.files
        form_data.append 'files[]', file
      
      if data.category_id isnt null
        form_data.append 'category', data.category_id
      
      if data.ticket_id isnt null
        form_data.append 'ticketid', data.ticket_id

      res = await fetch api_url,
        mode: 'cors'
        method: 'post'
        body: form_data
      
      json = await res.json()

      ctx.commit 'set_loading', false

      if json.response.msg
        if not ctx.state.ticket
          router.push(
            name: 'support-ticket'
            params:
              id: json.response.msg
          )
        else
          ctx.dispatch 'get_messages', json.response.msg

    get_messages: (ctx, ticket_id) ->
      form_data = new FormData
      form_data.append 'method', 'services.getticketmessages'
      form_data.append 'token', VueCookies.get 'token'
      form_data.append 'ticketid', ticket_id

      tickets = ctx.state.tickets

      ticket_index = tickets.findIndex (item) ->
        item.id is ticket_id

      if ticket_index isnt -1
        ctx.commit 'set_ticket', tickets[ticket_index]

      ctx.commit 'set_loading', true

      res = await fetch api_url,
        mode: 'cors'
        method: 'post'
        body: form_data

      json = await res.json()

      ctx.commit 'set_loading', false

      if json.response.msg
        ctx.commit 'set_messages', json.response.msg.slice().reverse()

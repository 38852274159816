
import moment from 'moment'

import Button from '@/components/button'

export default
  name: 'services-my'

  components:
    'c-button': Button

  computed:
    is_mobile: ->
      screen.width < 760

    is_loading: -> @$store.getters.is_loading

    services: ->
      @$store.getters.services_my

  mounted: ->
    @$store.dispatch 'get_services'

  methods:
    format_date: (date) ->
      if date
        moment(date).format('DD.MM.YYYY')

    sign: ->
      console.log 'sign'


import icon from '@/components/icon'
import row from '@/components/row'
import col from '@/components/col'
import input from '@/components/input'
import button from '@/components/button'
import Multiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.min.css'

import VueCookies from 'vue-cookies'
import router from "../../router"

api_url = 'https://api.aostng.ru/api/lk/'

export default
  name: 'search'

  components:
    'c-icon': icon
    'c-row': row
    'c-col': col
    'c-input': input
    'c-button': button
    'multiselect': Multiselect

  data: ->
    address_street: null
    address_house: null
    address_apartment: null

    streets: []
    houses: []
    apartments: []

    selectedLc: null

    lc_empty: false
    last_name_empty: false

    error_text: null

    isAddressLoading: false
    isHousesLoading: false
    isApartmentsLoading: false

    timer: null

  computed:
    is_mobile: ->
      screen.width < 760

    isHasApartment: ->
      if @selectedLc == null
        return true
      if @apartments.length > 0
        return true
      return false

  mounted: () ->
    form_data = new FormData
    form_data.append 'method', 'connect.get'
    form_data.append 'method_connect', 'findLic'
    form_data.append 'get', "value="

    res = await fetch api_url,
      mode: 'cors'
      method: 'post'
      body: form_data

    json = await res.json()

    console.log 'mounted query res:', json

  methods:
    goToConnect: () ->
      @error_text = ''
      if @selectedLc == null
        @error_text = 'Введите данные'
        return
      router.push({ name: 'lc-connect', params: { LcSearch: @selectedLc } })

    asyncFindAddress: (query) ->
      @isAddressLoading = true
      @houses = []
      @apartments = []
      @selectedLc = null
      @address_house = null
      @address_apartment = null
      _self = @

      clearTimeout @timer

      @timer = setTimeout(( ->
        form_data = new FormData
        form_data.append 'method', 'connect.get'
        form_data.append 'method_connect', 'findLic'
        form_data.append 'get', "value=" + query.split(' ').join('+')

        res = await fetch api_url,
          mode: 'cors'
          method: 'post'
          body: form_data

        json = await res.json()

        if json.response.msg
          _self.streets = json.response.msg.streets
          _self.isAddressLoading = false
      ), 1000)

    asyncFindHouses: ->
      @isHousesLoading = true
      @apartments = []
      @selectedLc = null
      @address_house = null
      @address_apartment = null

      jsonQuery = {
        'streetId': @address_street.id
      }

      form_data = new FormData
      form_data.append 'method', 'connect.get'
      form_data.append 'method_connect', 'findLic'
      form_data.append 'json', JSON.stringify(jsonQuery)

      res = await fetch api_url,
        mode: 'cors'
        method: 'post'
        body: form_data

      json = await res.json()

      if json.response.msg
        @houses = json.response.msg.houses
        @isHousesLoading = false

    asyncFindApartments: ->
      @isApartmentsLoading = true
      @selectedLc = null
      @apartments = []
      @address_apartment = null

      jsonQuery = {
        'streetId': @address_street.id,
        'house': @address_house,
      }

      form_data = new FormData
      form_data.append 'method', 'connect.get'
      form_data.append 'method_connect', 'findLic'
      form_data.append 'json', JSON.stringify(jsonQuery)

      res = await fetch api_url,
        mode: 'cors'
        method: 'post'
        body: form_data

      json = await res.json()

      if json.response.msg.apartmentes
        @apartments = json.response.msg.apartmentes
        @isApartmentsLoading = false
      else
        @selectedLc = json.response.msg.lc
        @isApartmentsLoading = false

    nameWithParent: ({ id, name, parent }) ->
      return parent + ', ' + name

  watch:
    address_street: ->
      @asyncFindHouses()
    address_house: ->
      @asyncFindApartments()
    address_apartment: ->
      if (@address_apartment?.lc?)
        @selectedLc = @address_apartment.lc

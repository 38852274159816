
import CButton from './button'
import CIcon from './icon'

export default
  name: 'c-header'
  components:
    'c-button': CButton
    'c-icon': CIcon

  computed:
    mobile_menu: ->
      @$store.getters.mobile_menu

  methods:
    mobile_menu_toggle: ->
      @$store.commit 'set_mobile_menu', not @$store.getters.mobile_menu

    goToHome: -> window.location.href = 'https://aostng.ru/'

    goToProfile: ->
      @$router.push '/profile'

    goToLogout: -> window.location.href = 'https://aostng.ru/?logout=yes'

    contactsModal: ->
      @$store.commit 'set_modal_contacts', true

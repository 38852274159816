
import moment from 'moment'

export default
  name: 'support-list'
  
  props: ['items', 'limit']
  
  methods:
    get_class_object: (color) ->
      'status_red': color is 'red'
      'status_green': color is 'green_s' or color is 'green'
      'status_yellow': color is 'yellow'

    format_date: (date) ->
      moment(date).format('DD.MM.YYYY HH:mm')

    click: (e, id) ->
      if @$route.params.id isnt id
        @$router.push(
          name: 'support-ticket',
          params:
            id: id
        )

      @$emit('selectTicket', id)

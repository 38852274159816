
import Icon from '@/components/icon'
import SupportList from '@/components/support-list'
import Loading from '@/components/loading'

export default
  name: 'support-ticket'

  data: ->
    message: ''
    files: []
    filesError: false
    selected_ticket: null

  components:
    'c-icon': Icon,
    'c-support-list': SupportList
    'c-loading': Loading

  mounted: ->
    if not @ticket
      await @$store.dispatch 'support/init'

    await @$store.dispatch 'support/get_messages', @$route.params.id

    @scroll_chat()

  computed:
    tickets: -> @$store.getters['support/tickets']

    ticket: -> @$store.getters['support/ticket']

    messages: -> @$store.getters['support/messages']

    is_loading: -> @$store.getters['support/is_loading']

  watch:
    '$route.params.id': (value) ->
      await @$store.dispatch 'support/get_messages', value
      
      @scroll_chat()

  methods:
    selectTicket: (ticket_id) ->
      @selected_ticket = ticket_id

    drop_file: (e, index) ->
      @filesError = false
      @files.splice index, 1
      @$refs.hidden_input.value = null

    download_file: (file) ->
      window.open file, '_blank'

    select_file: (e) ->
      files = e.target.files
      @filesError = false

      for file in files
        if file.size > 2097152
          @filesError = true
        else
          @files.push(file)

    scroll_chat: ->
      @$refs.chat.scrollTop = @$refs.chat.offsetHeight + 1000

    on_change_message: (e) ->
      @message = e.target.innerText

    send_message: (e) ->
      if @message isnt '' and @ticket
        await @$store.dispatch 'support/send_message',
          message: @message
          category_id: @ticket.category_id
          ticket_id: @ticket.id
          files: @files

        @message = ''
        @$refs.chatinput.innerText = ''
        @files = []
        @$refs.hidden_input.value = null
        @filesError = false

        @scroll_chat()


import icon from '@/components/icon'
import row from '@/components/row'
import col from '@/components/col'
import input from '@/components/input'
import button from '@/components/button'

import VueCookies from 'vue-cookies'

api_url = 'https://api.aostng.ru/api/lk/'

export default
  name: 'connect'

  components:
    'c-icon': icon
    'c-row': row
    'c-col': col
    'c-input': input
    'c-button': button

  data: ->
    form_data:
      lc_number: ''
      last_name: ''
      first_name: ''
      middle_name: ''

    lc_empty: false
    last_name_empty: false

    error_text: null

  computed:
    is_mobile: ->
      screen.width < 760

    is_loading: -> @$store.getters.is_loading

  mounted: ->
    if (this.$route.params.LcSearch?)
      @form_data.lc_number = this.$route.params.LcSearch

  methods:
    connect_handle: ->
      # @$store.dispatch 'connect', @form_data

    click: ->
      @lc_empty = false
      @last_name_empty = false

      if not @form_data.lc_number then @lc_empty = true
      if not @form_data.last_name then @last_name_empty = true

      if not @lc_empty and not @last_name_empty
        @$store.commit 'set_loading', true
        @error_text = null

        form_data = new FormData
        form_data.append 'method', 'user.adduserlk'
        form_data.append 'lc', @form_data.lc_number
        form_data.append 'firstname', @form_data.first_name
        form_data.append 'lastname', @form_data.last_name

        if VueCookies.get 'token'
          form_data.append 'token', VueCookies.get 'token'

        res = await fetch api_url,
          mode: 'cors'
          method: 'post'
          body: form_data

        json = await res.json()

        @$store.commit 'set_loading', false

        if json.error
          @error_text = json.error.msg
        else
          if json.response.msg.length > 0
            lcs = json.response.msg.map (item) ->
              item[0]

            lcs = lcs.join ','

            @$store.dispatch 'get_lc', lcs

            @$router.push({ name: 'lc' })


import Icon from '@/components/icon'
import Input from '@/components/input'
import Loading from '@/components/loading'

export default
  name: 'support-create'

  components:
    'c-icon': Icon
    'c-input': Input
    'c-loading': Loading

  data: ->
    selected_category: ''
    message: ''
    files: []
    filesError: false

  mounted: ->
    @$store.commit 'support/set_ticket', null

  computed:
    categories: -> @$store.getters['support/categories']

    is_loading: -> @$store.getters['support/is_loading']

  methods:
    on_change_message: (e) ->
      @message = e.target.innerText

    send_message: (e) ->
      if @message isnt '' and @selected_category isnt ''
        @$store.dispatch 'support/send_message',
          message: @message
          category_id: +@selected_category.value
          files: @files

        @message = ''
        @filesError = false

    select_file: (e) ->
      files = e.target.files

      for file in files
        if file.size > 2097152
          @filesError = true
        else
          @files.push(file)

    drop_file: (e, index) ->
      @files.splice index, 1
      @filesError = false

    select_category: (value) -> @selected_category = value


import Table from '@/components/table'
import Loading from '@/components/loading'
import Icon from '@/components/icon'

export default
  name: 'history'

  components:
    'c-table': Table
    'c-loading': Loading
    'c-icon': Icon

  data: ->
    table_columns: [
      label: 'Дата платежа'
      size: 'small'
    ,
      label: 'Сумма'
      size: 'medium'
    ,
      label: 'Показания',
      size: 'auto'
    ]

  computed:
    table_data: ->
      data = []
      if @$store.getters.lc_history
        for item, item_index in @$store.getters.lc_history
          if item.date isnt null and item.value isnt null
            data.push [item.date, "#{item.value.toLocaleString('ru-RU')} руб.", item.counters]
      data.slice().reverse()

    is_loading: ->
      @$store.getters.is_loading

    is_mobile: ->
      screen.width < 760

  mounted: ->
    @$store.commit 'select_lc', @$route.params.lc
    @$store.dispatch 'get_lc_history',
      number: @$route.params.lc
      start: '20151231000000'
      end: '20301231000000'
      is_pay: '1'

  methods:
    get_class_object: (size) ->
      'cell_small': size is 'small'
      'cell_auto': size is 'auto'
      'cell_medium': size is 'medium'


import Icon from '@/components/icon'
import Loading from '@/components/loading'

export default
  name: 'lc-counter'
  components:
    'c-icon': Icon
    'c-loading': Loading

  data: ->
    counter_name: null

  computed:
    is_mobile: ->
      screen.width < 760

    records: ->
      records = []

      if @$store.getters.lc_history
        for history_item, history_item_index in @$store.getters.lc_history
          if history_item.counters
            that = @

            result = history_item.counters.findIndex (item) ->
              item.id is that.$route.params.id
            
            if result isnt -1
              @counter_name = history_item.counters[result].name
              records.push(history_item.counters[result])

        records = @get_unique records
        records = records.reverse()

      records

    is_loading: ->
      @$store.getters.is_loading

  mounted: ->
    @$store.dispatch 'get_lc_history',
      number: @$route.params.lc
      start: '20151231000000'
      end: '20301231000000'
      is_pay: '0'

  methods:
    get_unique: (arr) ->
      i = 0
      unique = []

      for item, item_index in arr
        result = unique.findIndex (i) ->
          item.dateind is i.dateind and item.id is i.id and item.indication is i.indication

        if result is -1
          unique.push(item)

      unique


export default
  name: 'c-table'
  props: ['columns', 'data', 'history']

  computed:
    is_mobile: ->
      screen.width < 760

  methods:
    get_class_object: (size) ->
      'cell_small': size is 'small'
      'cell_auto': size is 'auto'
      'cell_medium': size is 'medium'
